
import { defineComponent } from "vue";
import UserMenu from "@/layout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "kt-summary",
  components: {
    UserMenu,
  },
});
